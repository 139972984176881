.container {
    width: 100%;
    background: rgba(217, 217, 217, 0.04);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    margin: 1rem;
    padding: 2rem 1.5rem;
    box-shadow: 0px 4px 20px 0px rgba(0, 10, 46, 0.2);
}

.position {
    font-size: 1.5rem;
    font-weight: 400;
    color: #ffffff;
    margin-right: 0.5rem;
}
.companyName {
    font-size: 1.5rem;
    font-weight: 800;
    color: #56aeff;
}
.duration {
    color: #a8b2d1;
    font-size: 0.8rem;
}
.workDone {
    color: #8892b2;
    margin: 1rem;
    width: 65%;
}
.workDone > ul > li {
    margin: 0.5rem 0 0;
}
.techStack {
    border-left: 1px rgba(156, 156, 156, 0.61) solid;
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0 0 0 1rem;
}
.item {
    background: rgba(72, 209, 253, 0.11);
    color: #48d1fd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-size: 0.8rem;
    padding: 0 0.5rem;
    border-radius: 1rem;
    margin: 0.5rem 0.5rem 0 0;
}
.superContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}
@media screen and (max-width: 900px) {
    .techStack {
        width: 100%;
        border-left: none;
        border-top: 1px rgba(156, 156, 156, 0.61) solid;
        padding: 1rem 0 0 0;
    }
    .workDone {
        width: 90%;
    }
    .superContainer {
        flex-direction: column;
    }
    .workDone > ul {
        text-align: center;
    }
    .companyName,
    .position {
        display: inline-block;
    }
    .container {
        padding: 1rem 1rem;
    }
}
