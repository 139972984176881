.container {
    width: 100%;
    background-color: #1b1b1b;
    height: 2rem;
    border-radius: 0.2rem 0.22rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #babbbf;
    padding: 0 1rem;
}
.logoContainer {
    height: 100%;
}
.logoContainer > img {
    height: 90%;
}

@media screen and (max-width:600px) {
    .container{
        padding:0 0.5rem;
    }
}