.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 3rem 15%;
    overflow-x: hidden;
}
.greyLine {
    flex: 1;
    background: rgba(156, 156, 156, 0.61);
    height: 1px;
    cursor: pointer;
    margin: 0 0.5rem;
}
.containerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    gap: 1rem;
}
.title {
    font-weight: bold;
    font-size: 2.5rem;
    color: #ffffff;
    width: auto;
}

.containerContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: fit-content;
    gap: 2.5rem;
}
.contentLeft {
    display: flex;
    /* justify-content: space-around; */
    flex-direction: column;
    /* height: max-content; */
    width: 40%;
}
.contentRight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
}

.brandLogos {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
}
.brandLogos > img {
    cursor: pointer;
    width: 1.2rem;
}
.ProfilePic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.profilePicImage {
    width: 100%;
}

.qoutesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* .qoutes {
    width: calc;
} */

.qoute {
    color: #48d1fd;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    margin: 1.5rem 0 0.5rem 0;
}

.author {
    color: #a8b2d1;
    font-style: italic;
    font-weight: 600;
    font-size: 0.6rem;
    text-align: center;
}

.contentText {
    color: #a8b2d1;
    font-size: 0.8rem;
}
.highlight {
    font-size: 0.9rem;
    color: #56aeff;
    font-weight: 600;
}
.ResumeBox {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    color: #56aeff;
    justify-content: space-between;
    align-items: center;
}
.resumeText {
    font-size: 0.9rem;
    font-weight: 600;
}
.resumeButtonContainer {
    flex: 1;
    text-align: center;
}
.resumeButton {
    border: 1px solid #48d1fd;
    background: rgba(72, 209, 253, 0.17);
    color: #48d1fd;
    height: 2rem;
    width: 6rem;
    border-radius: 1rem;
    cursor: pointer;
}
.container::-webkit-scrollbar {
    width: 0.5rem;
}
.container::-webkit-scrollbar-track {
    background: rgba(72, 209, 253, 0.11);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.container::-webkit-scrollbar-thumb {
    background-color: rgba(72, 209, 253, 0.3);
    outline: 1px solid slategrey;
    border-radius: 1rem;
}
.blankdiv {
    width: 3rem;
}

@media screen and (max-width: 900px) {
    .containerContent {
        flex-direction: column;
    }
    .contentLeft {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .brandLogos {
        flex-direction: row;
        margin: 1rem 0;
        width: 100%;
    }
    .contentText {
        text-align: center;
    }
    .contentRight {
        width: 100%;
    }
    .qoutesContainer {
        width: 100%;
    }
    .ProfilePic {
        flex-direction: column-reverse;
        align-items: center;
    }
    .blankdiv {
        display: none;
    }.qoutes{
        width: 100%;
    }
    .emptyDiv{
        display: none;
    }
}
