.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;
}
.imageContainer > div > img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.childContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.imageContainer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectTitle {
    color: #56aeff;
    font-weight: 500;
    font-size: 2rem;
}
.projectDescription {
    color: #8892b2;
    margin: 1rem 0;
    flex-grow: 1;
    /* background-color: #56aeff; */
}

.imageContainer > div:hover {
    opacity: 0.5;
}
.imageContainer > div:hover .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: none;
    background-color: rgb(86, 174, 255, 0.6);
}

.overlay > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    opacity: 1;
}
.projectTechStack > p {
    color: #56aeff;
    font-weight: 700;
}
.projectTechStack {
    width: 1fr;
    font-size: 0.89rem;
    background: #141c3c;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem 0.5rem;
    margin-bottom: 10%;
    transform: translateX(-50%);
}
.item {
    background: rgba(72, 209, 253, 0.11);
    color: #48d1fd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-size: 0.8rem;
    padding: 0 0.5rem;
    border-radius: 1rem;
    margin: 0.5rem 0.5rem 0 0;
    width: fit-content;
}

.projectTechStack > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
@media screen and (max-width: 1200px) {
    .projectTechStack {
        transform: none;
    }
    .container {
        flex-direction: column;
    }
    .imageContainer {
        width: 100%;
    }
    .childContainer {
        width: 100%;
    }
}
