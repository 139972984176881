.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 3rem 15%;
    overflow-x: hidden;
}
.containerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    gap: 1rem;
}
.title {
    font-weight: bold;
    font-size: 2.5rem;
    color: #ffffff;
    width: auto;
}
.greyLine {
    flex: 1;
    background: rgba(156, 156, 156, 0.61);
    height: 1px;
    cursor: pointer;
    margin: 0 0.2rem;
}

.container::-webkit-scrollbar {
    width: 0.5rem;
}
.container::-webkit-scrollbar-track {
    background: rgba(72, 209, 253, 0.11);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.container::-webkit-scrollbar-thumb {
    background-color: rgba(72, 209, 253, 0.3);
    outline: 1px solid slategrey;
    border-radius: 1rem;
}
.blueLine {
    flex: 1;
    background-color: #3a9ffd;
    height: 2px;
    margin: 0 0.2rem;
}

.containerLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-bottom: 2rem;
}
.arrow {
    margin: 0 0.4rem;
    color: #3a9ffd;
    size: 2rem;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .container {
        padding: 1rem 10%;
    }
}
@media screen and (max-width: 600px) {
    .container {
        padding: 1rem 5%;
    }
    .containerLine {
        width: 100%;
        order: 2;
    }
    .projectContainer {
        order: 3;
    }
}
